import { toast } from "react-toastify";

const errorHandler = (error) => {
    // console.log('error :: ' + error.message);
    toast.error(error.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });
}

export default errorHandler;