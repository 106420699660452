import React, { useContext, useEffect, useState } from "react";
import { ChatContext } from "../../../../ContextApi/ChatProvider";
import { Box, Stack, Text, useToast } from "@chakra-ui/react";
import axios from "axios";
import ChatLoading from "../ChatLoading";
import { useSelector } from "react-redux";

const MyChats = ({ fetchAgain }) => {
    const [loggedUser, setLoggedUser] = useState();
    const userData = useSelector((state) => state.user.userData);
    const {
        user,
        selectedChat,
        setSelectedChat,
        chats,
        setChats,
        setNewMessage,
        setMessages,
        setIsTyping,
        setTyping,
    } = useContext(ChatContext);
    const toast = useToast();

    const fetchChats = async () => {
        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`,
            },
        };
        await axios
            .get(`${process.env.REACT_APP_FRONTAPICOMMAN}/get-all-chat`, config)
            .then((data) => {
                // setChats(data.data?.results);
                // function findBlocks(results, MultiPleUserIds) {
                //   const blocks = [];
                //   results.forEach((result, id) => {
                //     const users = result.users.map((user) => user._id);
                //     const hasMatch = MultiPleUserIds.some((userId) =>
                //       users.includes(userId)
                //     );
                //     // if (!hasMatch) {
                //     blocks[id] = { ...result, block: hasMatch };
                //     // }
                //   });
                //   return blocks;
                // }
                function findBlockss(results, MultiPleUserIds, MultiPleUserByIds) {
                    const blocks = [];
                    results.forEach((result, id) => {
                        const users = result.users.map((user) => user._id);
                        const hasMatch = MultiPleUserIds.some((userId) =>
                            users.includes(userId)
                        );
                        const hasMatchs = MultiPleUserByIds.some((userId) =>
                            users.includes(userId)
                        );
                        // if (!hasMatch) {
                        blocks[id] = { ...result, block: hasMatch, BlockByUser: hasMatchs };
                        // }
                    });
                    return blocks;
                }
                setChats(
                    findBlockss(
                        data.data?.results,
                        data?.data?.MultiPleUserIds,
                        data?.data?.MultiPleUserByIds
                    )
                );
            })
            .catch(() => {
                toast({
                    title: "Error Occured!!",
                    description: "Failed to Load the chats",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                    position: "bottom-left",
                });
            });
    };
    useEffect(() => {
        setLoggedUser(user);
        fetchChats();
        // eslint-disable-next-line
    }, [fetchAgain]);

    return (
        <Box
            display={{ base: selectedChat ? "none" : "flex", md: "flex" }}
            flexDir="column"
            alignItems="center"
            justifyContent={"space-between"}
            p={3}
            bg="white"
            w={{ base: "100%", md: "31%" }}
            borderRadius="lg"
            borderWidth="1px"
        >
            <Box pb={3} px={3} fontSize={{ base: "28px", md: "22px" }} display="flex" w="100%" justifyContent="space-between" alignItems="center">
                Chat History
            </Box>
            <Box display="flex" flexDir="column" p={3} bg="#F8F8F8" w="100%" h="100%" borderRadius="lg" overflowY="hidden">
                {chats ? (
                    <Stack overflowY="scroll" className="h-full">
                        {chats.map((chat) => (
                            <Box
                                onClick={() => {
                                    if (chat._id !== selectedChat?._id) {
                                        setSelectedChat(chat);
                                        setNewMessage("");
                                        setMessages([]);
                                        setIsTyping(false);
                                        setTyping(false);
                                    }
                                }}
                                cursor="pointer"
                                bg={selectedChat === chat ? "#f1939b" : "#ff607b"}
                                color="white"
                                px={3}
                                py={2}
                                borderRadius="lg"
                                key={chat._id}
                            >
                                <Text>
                                    {!chat.isGroupChat ? (chat.users[0]?._id === userData?._id ? chat.users[1]?.fristname : chat.users[0]?.fristname) : chat.chatName}
                                </Text>
                            </Box>
                        ))}
                    </Stack>
                ) : (
                    <ChatLoading />
                )}
            </Box>
        </Box>
    );
};

export default MyChats;
