import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import authSlice from "./slice/AuthSlice";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { combineReducers } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import { FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";

const reducer = combineReducers({ user: authSlice });
const persist = persistReducer({ key: "root", version: "1", storage }, reducer);

const store = configureStore({
    reducer: persist,
    middleware: [
        ...getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
        thunk,
    ],
    devTools: true,
});

export default store;
