import { useContext, useEffect, useState } from "react";
import { ChatContext } from "../../../../ContextApi/ChatProvider";
import { Box } from "@chakra-ui/react";
import SideDrawer from "../Components/miscellaneous/SideDrawer";
import MyChats from "../Components/MyChats";
import ChatBox from "../Components/ChatBox";
import { useSelector } from "react-redux";
import Index from "../..";

const ChatPage = () => {
    const userData = useSelector((state) => state.user.userData);
    const Token = useSelector((state) => state.user.token);
    const { user, setUser } = useContext(ChatContext);
    useEffect(() => {
        const userInfo = {
            token: Token,
            _id: userData?._id,
            email: userData?.email,
            gender: userData?.gender,
        };
        setUser(userInfo);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [fetchAgain, setFetchAgain] = useState(false);
    return (
        <>
            <Index />
            <div style={{ width: "100%" }}>
                {user && <SideDrawer />}
                <Box display="flex" justifyContent={"space-between"} w={"100%"} h={"91.5vh"} p={"10px"}>
                    {user && <MyChats fetchAgain={fetchAgain} />}
                    {user && <ChatBox fetchAgain={fetchAgain} setFetchAgain={setFetchAgain} />}
                </Box>
            </div>
        </>
    );
};

export default ChatPage;
