import { BellIcon, CloseIcon } from "@chakra-ui/icons";
import {
    Box,
    Button,
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    IconButton,
    Input,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Spinner,
    Text,
    Tooltip,
    useDisclosure,
    useToast,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { BsSearch } from "react-icons/bs";
import { ChatContext } from "../../../../../ContextApi/ChatProvider";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ChatLoading from "../../ChatLoading";
import UserListItem from "../UserAvatar/UserListItem";
import { getSender } from "../../config/ChatLogics";
import NotificationBadge, { Effect } from "react-notification-badge";

const SideDrawer = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();
    const {
        user,
        setSelectedChat,
        chats,
        setChats,
        notification,
        chatCount,
        setChatCount,
        setNotification,
    } = useContext(ChatContext);
    const Navigate = useNavigate();

    const [search, setSearch] = useState("");
    const [searchResult, setSearchResult] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingChat, setLoadingChat] = useState(false);

    const getChatCountApi = async () => {
        try {
            const config = {
                headers: {
                    "Content-type": "application/json",
                    Authorization: `Bearer ${user.token}`,
                },
            };
            await axios
                .get(
                    `${process.env.REACT_APP_FRONTAPICOMMAN}/get-all-message-count`,
                    config
                )
                .then((res) => {
                    setChatCount(res.data?.length === 0 ? 0 : res.data[0]?.total);
                });
        } catch (error) {
            toast({
                description: error.message,
                status: "error",
                duration: 3000,
                isClosable: true,
                position: "bottom-left",
            });
        }
    };
    useEffect(() => {
        getChatCountApi();
    }, [chats]);

    const handleSearch = async () => {
        if (!search) {
            toast({
                title: "Please Enter something in search",
                status: "warning",
                duration: 3000,
                isClosable: true,
                position: "top-left",
            });
            return;
        }

        try {
            setLoading(true);

            const config = {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            };

            const data = await axios.get(
                `${process.env.REACT_APP_FRONTAPICOMMAN}/get-all-user-chat?search=${search}`,
                config
            );
            setLoading(false);
            setSearchResult(data.data);
        } catch (error) {
            toast({
                title: "Error Occured!",
                description: "Failed to Load the Search Results",
                status: "error",
                duration: 3000,
                isClosable: true,
                position: "bottom-left",
            });
        }
    };
    const accessChat = async (userId) => {
        if (chatCount < 15) {
            try {
                setLoadingChat(true);
                const config = {
                    headers: {
                        "Content-type": "application/json",
                        Authorization: `Bearer ${user.token}`,
                    },
                };
                const data = await axios.post(
                    `${process.env.REACT_APP_FRONTAPICOMMAN}/create-access`,
                    { userId },
                    config
                );

                if (!chats.find((c) => c._id === data.data._id))
                    setChats([data.data, ...chats]);
                setSelectedChat(data.data);
                setLoadingChat(false);
                onClose();
            } catch (error) {
                toast({
                    title: "Error fetching the chat",
                    description: error.message,
                    status: "error",
                    duration: 2000,
                    isClosable: true,
                    position: "bottom-left",
                });
            }
        } else {
            toast({
                title:
                    "Your Daily Limit of Chat is Completed Hence You Can Chat Tommorrow",
                status: "error",
                duration: 2000,
                isClosable: true,
                position: "bottom-left",
            });
        }
    };

    return (
        <>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                bg="white"
                w="100%"
                p="5px 10px 5px 10px"
                borderWidth="5px"
            >
                <Tooltip label="Search User" hasArrow placement="bottom">
                    <Button variant={"ghost"} onClick={onOpen}>
                        <BsSearch />
                        <Text display={{ base: "none", md: "flex" }} px={"4"}>
                            Search User
                        </Text>
                    </Button>
                </Tooltip>
                <div>
                    <Menu>
                        <MenuButton p={"1"}>
                            <NotificationBadge
                                count={notification.length}
                                effect={["fade", "pop"]}
                            />
                            <BellIcon fontSize={"4xl"} m={"2"} />
                        </MenuButton>
                        <MenuList pl={"2"}>
                            {!notification.length && "No New Messages"}
                            {notification.map((notif) => (
                                <MenuItem
                                    key={notif._id}
                                    onClick={() => {
                                        setSelectedChat(notif.chat);
                                        setNotification(
                                            notification.filter(
                                                (n) => n?.chat?._id !== notif?.chat?._id
                                            )
                                        );
                                    }}
                                >
                                    {notif?.chat.isGroupChat
                                        ? ``
                                        : `New Message From ${getSender(user, notif?.chat?.users)}`}
                                </MenuItem>
                            ))}
                        </MenuList>
                    </Menu>
                </div>
            </Box>
            <Drawer
                placement="left"
                size={"sm"}
                onClose={() => {
                    onClose();
                    setSearchResult([]);
                    setSearch("");
                }}
                isOpen={isOpen}
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerHeader
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        borderBottomWidth="1px"
                    >
                        <Box>Search Users</Box>
                        {/* <Tooltip
              label="Close Sidebar"
              placement="bottom-start"
              hasArrow
              fontSize="xl"
            > */}
                        <IconButton
                            bg={"#ff607b"}
                            _hover={"#ff607b"}
                            d={{ base: "flex", md: "none" }}
                            icon={<CloseIcon color={"white"} />}
                            onClick={() => {
                                onClose();
                                setSearchResult([]);
                                setSearch("");
                            }}
                        />
                        {/* </Tooltip> */}
                    </DrawerHeader>
                    <DrawerBody>
                        <Box display="flex" pb={2}>
                            <Input
                                placeholder="Search by name or email"
                                mr={2}
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                            <Button
                                onClick={handleSearch}
                                bg={"#ff607b"}
                                _hover={"#ff607b"}
                                borderRadius={"none"}
                                color={"white"}
                            >
                                Go
                            </Button>
                        </Box>
                        {loading ? (
                            <ChatLoading />
                        ) : (
                            searchResult?.map((user) => (
                                <UserListItem
                                    key={user?._id}
                                    user={user}
                                    handleFunction={() => accessChat(user?._id)}
                                />
                            ))
                        )}
                        {loadingChat && <Spinner ml={"auto"} display={"flex"} />}
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    );
};

export default SideDrawer;
