import { NotAllowedIcon } from "@chakra-ui/icons";
import { IconButton, Tooltip } from "@chakra-ui/react";
import axios from "axios";
import React from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { ChatContext } from "../../../../../ContextApi/ChatProvider";
import { useContext } from "react";

const ProfileModal = ({ user }) => {
  const Token = useSelector((state) => state.user.token);
  const userData = useSelector((state) => state.user.userData);
  const { setSelectedChat, selectedChat, setBlock, setChats } =
    useContext(ChatContext);
  const headers = {
    Authorization: `Bearer ${Token}`,
    "Content-Type": "application/x-www-form-urlencoded",
  };
  const fetchChats = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    await axios
      .get(`${process.env.REACT_APP_FRONTAPICOMMAN}/get-all-chat`, config)
      .then((data) => {
        function findBlockss(results, MultiPleUserIds, MultiPleUserByIds) {
          const blocks = [];
          results.forEach((result, id) => {
            const users = result.users.map((user) => user._id);
            const hasMatch = MultiPleUserIds.some((userId) =>
              users.includes(userId)
            );
            const hasMatchs = MultiPleUserByIds.some((userId) =>
              users.includes(userId)
            );
            // if (!hasMatch) {
            blocks[id] = { ...result, block: hasMatch, BlockByUser: hasMatchs };
            // }
          });
          return blocks;
        }
        setChats(
          findBlockss(
            data.data?.results,
            data?.data?.MultiPleUserIds,
            data?.data?.MultiPleUserByIds
          )
        );
      })
      .catch(() => {
        toast({
          title: "Error Occured!!",
          description: "Failed to Load the chats",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "bottom-left",
        });
      });
  };
  const blockPerson = (id) => {
    const params = new URLSearchParams();
    params.append("bp_userId", userData._id);
    params.append("bp_block_userId", user._id);
    Swal.fire({
      text: "Are You Sure to block this Profile",
      icon: "warning",
      confirmButtonText: "Yes",
      showCancelButton: true,
    }).then((res) => {
      if (res.isConfirmed) {
        axios
          .post(
            `${process.env.REACT_APP_FRONTAPI}/block-profile/store`,
            params,
            { headers }
          )
          .then((res) => {
            toast.error(`Profile Blocked`, {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            fetchChats();
            setSelectedChat("");
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };
  return (
    <>
      {selectedChat?.block || selectedChat?.BlockByUser ? (
        <div className="invisible"></div>
      ) : (
        <Tooltip label="Block" placement="bottom-start" hasArrow fontSize="xl">
          <IconButton
            display={"hidden"}
            icon={<NotAllowedIcon fontSize={"2xl"} />}
            onClick={blockPerson}
          />
        </Tooltip>
      )}
    </>
  );
};

export default ProfileModal;
