import React, { useContext } from "react";
import { ChatContext } from "../../../../../ContextApi/ChatProvider";
import { Avatar, Box, Text } from "@chakra-ui/react";

const UserListItem = ({ user, handleFunction }) => {
  return (
    <Box
      onClick={handleFunction}
      cursor="pointer"
      bg="#ff607b"
      _hover={{
        background: "#f1939b",
      }}
      color={"white"}
      w="100%"
      display="flex"
      alignItems="center"
      px={3}
      py={2}
      mb={2}
      borderRadius="lg"
    >
      <Avatar
        mr={2}
        size="sm"
        cursor="pointer"
        name={user?.fristname}
        src={user.pic}
      />
      <Box>
        <Text>{`${user?.fristname} (${user?.user_uniqe_code})`}</Text>
      </Box>
    </Box>
  );
};

export default UserListItem;
