import React, { useContext, useEffect, useRef, useState } from "react";
import { ChatContext } from "../../../../ContextApi/ChatProvider";
import { Box, Button, FormControl, IconButton, Input, Spinner, Text, Tooltip, useToast } from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { getSenderFull } from "../config/ChatLogics";
import ProfileModal from "./miscellaneous/ProfileModal";
import axios from "axios";
import ScrollableChat from "./ScrollableChat";
import io from "socket.io-client";
import Lottie from "react-lottie";
import animationData from "../animations/animation_type.json";
import { BASE_URL } from "../config/constants";
import { BsEmojiSmile } from "react-icons/bs";
import EmojiPicker from "@emoji-mart/react";
import { useSelector } from "react-redux";

const ENDPOINT = `${BASE_URL}`;
var socket, selectedChatCompare;

const SingleChat = ({ fetchAgain, setFetchAgain }) => {
    const { user, selectedChat, setSelectedChat, notification, setNotification, chatCount, setChatCount, newMessage, setNewMessage, typing, setTyping, messages, setMessages, istyping, setIsTyping } = useContext(ChatContext);
    const [loading, setLoading] = useState(false);
    const [socketConnected, setSocketConnected] = useState(false);
    const [showEmojis, setShowEmojis] = useState(false);
    const dropdownRef = useRef(null);
    const toast = useToast();
    const userData = useSelector((state) => state.user.userData);
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    const toggleDropdown = () => {
        setShowEmojis(!showEmojis);
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current?.contains(event.target)) {
            setShowEmojis(false);
        }
    };

    const fetchMessages = async () => {
        if (!selectedChat?._id) return;

        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            };

            setLoading(true);
            const data = await axios.get(
                `${process.env.REACT_APP_FRONTAPICOMMAN}/get-all-message?chatId=${selectedChat?._id}`,
                config
            );
            setMessages(data.data);
            setLoading(false);

            socket.emit("join chat", selectedChat?._id);
        } catch (error) {
            toast({
                title: "Error Occured!",
                description: "Failed to Load the Messages",
                status: "error",
                duration: 5000,
                isClosable: true,
                position: "bottom",
            });
        }
    };

    const sendMessage = async (event) => {
        const sameSender = messages[0]?.sender?._id === userData?._id;
        if (event.key === "Enter" && newMessage) {
            // socket.emit("stop typing", selectedChat?._id);
            socket.emit("stop typing", { room: selectedChat._id, user: userData });
            if (messages.length !== 0 && sameSender) {
                const config = {
                    headers: {
                        "Content-type": "application/json",
                        Authorization: `Bearer ${user.token}`,
                    },
                };
                setNewMessage("");
                await axios
                    .post(
                        `${process.env.REACT_APP_FRONTAPICOMMAN}/create-message`,
                        {
                            content: newMessage,
                            chatId: selectedChat?._id,
                        },
                        config
                    )
                    .then((res) => {
                        socket.emit("new message", res.data?.message);
                        setMessages([...messages, res.data?.message]);
                        setChatCount(res.data?.countUser[0]?.total);
                    })
                    .catch((err) => {
                        toast({
                            title: "Error Occured!",
                            description: "Failed to send the Message",
                            status: "error",
                            duration: 2000,
                            isClosable: true,
                            position: "bottom",
                        });
                    });
            } else if (chatCount < 15) {
                // socket.emit("stop typing", selectedChat?._id);
                socket.emit("stop typing", { room: selectedChat._id, user: userData });
                const config = {
                    headers: {
                        "Content-type": "application/json",
                        Authorization: `Bearer ${user.token}`,
                    },
                };
                setNewMessage("");
                await axios
                    .post(
                        `${process.env.REACT_APP_FRONTAPICOMMAN}/create-message`,
                        {
                            content: newMessage,
                            chatId: selectedChat?._id,
                        },
                        config
                    )
                    .then((res) => {
                        socket.emit("new message", res.data?.message);
                        setMessages([...messages, res.data?.message]);
                        setChatCount(res.data?.countUser[0]?.total);
                    })
                    .catch((err) => {
                        toast({
                            title: "Error Occured!",
                            description: "Failed to send the Message",
                            status: "error",
                            duration: 2000,
                            isClosable: true,
                            position: "bottom",
                        });
                    });
            } else {
                toast({
                    description:
                        "Your Daily Limit of Chat is Completed Hence You Can Chat Tommorrow",
                    status: "error",
                    duration: 2000,
                    isClosable: true,
                    position: "bottom",
                });
            }
        }
    };
    const sendMessageBtn = async (event) => {
        const sameSender = messages[0]?.sender?._id === userData?._id;
        if (messages.length !== 0 && sameSender) {
            // socket.emit("stop typing", selectedChat?._id);
            socket.emit("stop typing", { room: selectedChat._id, user: userData });
            const config = {
                headers: {
                    "Content-type": "application/json",
                    Authorization: `Bearer ${user.token}`,
                },
            };
            setNewMessage("");
            await axios
                .post(
                    `${process.env.REACT_APP_FRONTAPICOMMAN}/create-message`,
                    {
                        content: newMessage,
                        chatId: selectedChat?._id,
                    },
                    config
                )
                .then((res) => {
                    socket.emit("new message", res.data?.message);
                    setMessages([...messages, res.data?.message]);
                    setChatCount(res.data?.countUser[0]?.total);
                })
                .catch((err) => {
                    toast({
                        title: "Error Occured!",
                        description: "Failed to send the Message",
                        status: "error",
                        duration: 2000,
                        isClosable: true,
                        position: "bottom",
                    });
                });
        } else if (chatCount < 15) {
            // socket.emit("stop typing", selectedChat?._id);
            socket.emit("stop typing", { room: selectedChat._id, user: userData });
            const config = {
                headers: {
                    "Content-type": "application/json",
                    Authorization: `Bearer ${user.token}`,
                },
            };
            setNewMessage("");
            await axios
                .post(
                    `${process.env.REACT_APP_FRONTAPICOMMAN}/create-message`,
                    {
                        content: newMessage,
                        chatId: selectedChat?._id,
                    },
                    config
                )
                .then((res) => {
                    socket.emit("new message", res.data?.message);
                    setMessages([...messages, res.data?.message]);
                    setChatCount(res.data?.countUser[0]?.total);
                })
                .catch((err) => {
                    toast({
                        title: "Error Occured!",
                        description: "Failed to send the Message",
                        status: "error",
                        duration: 2000,
                        isClosable: true,
                        position: "bottom",
                    });
                });
        } else {
            toast({
                description:
                    "Your Daily Limit of Chat is Completed Hence You Can Chat Tommorrow",
                status: "error",
                duration: 2000,
                isClosable: true,
                position: "bottom",
            });
        }
    };

    useEffect(() => {
        fetchMessages();
        selectedChatCompare = selectedChat;

        // eslint-disable-next-line
    }, [selectedChat]);

    useEffect(() => {
        socket = io(ENDPOINT);
        socket.emit("setup", user);
        socket.on("connected", () => setSocketConnected(true));
        socket.on("typing", (typingData) => {
            if (typingData.room === selectedChatCompare?._id) {
                setIsTyping(true);
            }
        });
        // Listen for "stop typing" event from the server
        socket.on("stop typing", (typingData) => {
            if (typingData.room === selectedChatCompare?._id) {
                setIsTyping(false);
            }
        });
        // eslint-disable-next-line
        return () => {
            socket.off("typing");
            socket.off("stop typing");
        };
    }, []);

    useEffect(() => {
        socket.on("message recieved", (newMessageRecieved) => {
            if (
                !selectedChatCompare ||
                selectedChatCompare._id !== newMessageRecieved.chat._id
            ) {
                if (!notification.includes(newMessageRecieved)) {
                    setNotification([newMessageRecieved, ...notification]);
                    setFetchAgain(!fetchAgain);
                }
            } else {
                setMessages([...messages, newMessageRecieved]);
            }
        });
    });

    const typingHandler = (e) => {
        setNewMessage(e.target.value);

        if (!socketConnected) return;
        if (!typing) {
            setTyping(true);
            // socket.emit("typing", selectedChat?._id);
            socket.emit("typing", { room: selectedChat._id, user: userData });
        }
        let lastTypingTime = new Date().getTime();
        var timerLength = 3000;
        setTimeout(() => {
            var timeNow = new Date().getTime();
            var timeDiff = timeNow - lastTypingTime;
            if (timeDiff >= timerLength && typing) {
                // socket.emit("stop typing", selectedChat?._id);
                socket.emit("stop typing", { room: selectedChat._id, user: userData });
                setTyping(false);
            }
        }, timerLength);
    };

    const addEmoji = (e) => {
        let sym = e.unified.split("-");
        let codesArray = [];
        sym.forEach((el) => codesArray.push("0x" + el));
        let emoji = String.fromCodePoint(...codesArray);
        setNewMessage((prev) => prev + emoji);
    };
    return (
        <>
            {selectedChat ? (
                <>
                    <Box
                        fontSize={{ base: "28px", md: "30px" }}
                        pb={3}
                        px={2}
                        w="100%"
                        display="flex"
                        justifyContent={{ base: "space-between" }}
                        alignItems="center"
                    >
                        <Tooltip
                            label="Back to Chat List"
                            placement="bottom-start"
                            hasArrow
                            fontSize="xl"
                        >
                            <IconButton
                                bg={"#ff607b"}
                                hover={"#ff607b"}
                                d={{ base: "flex", md: "none" }}
                                icon={<ArrowBackIcon color={"white"} />}
                                onClick={() => {
                                    setSelectedChat("");
                                    setNewMessage("");
                                    setMessages([]);
                                    setIsTyping(false);
                                    setTyping(false);
                                }}
                            />
                        </Tooltip>
                        {!selectedChat?.isGroupChat ? (
                            <>
                                <div className="text-4xl">
                                    {selectedChat?.users[0]?._id === userData?._id ? selectedChat?.users[1]?.fristname : selectedChat?.users[0]?.fristname}
                                    {selectedChat?.block || selectedChat?.BlockByUser ? (
                                        <span className="text-red-500"> (Blocked)</span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <ProfileModal user={getSenderFull(user, selectedChat?.users)} />
                            </>
                        ) : (
                            <></>
                        )}
                    </Box>
                    <Box
                        display="flex"
                        flexDir="column"
                        justifyContent="flex-end"
                        p={3}
                        bg="#E8E8E8"
                        w="100%"
                        h="100%"
                        borderRadius="lg"
                        overflowY="hidden"
                    >
                        {loading ? (
                            <Spinner
                                size="xl"
                                w={20}
                                h={20}
                                alignSelf="center"
                                margin="auto"
                            />
                        ) : (
                            <Box
                                display={"flex"}
                                flexDirection={"column"}
                                overflowY={"scroll"}
                                style={{ scrollbarWidth: "none" }}
                                position={"relative"}
                            >
                                <ScrollableChat messages={messages} />
                            </Box>
                        )}
                        <FormControl
                            onKeyDown={sendMessage}
                            id="first-name"
                            isRequired
                            mt={3}
                        >
                            {istyping ? (
                                <div>
                                    <Lottie options={defaultOptions} width={70} style={{ marginBottom: 15, marginLeft: 0 }} />
                                </div>
                            ) : (
                                <></>
                            )}
                            <div className={`${selectedChat?.block || selectedChat?.BlockByUser ? "hidden" : "flex"} gap-5`}>
                                <Input
                                    variant="filled"
                                    bg="#E0E0E0"
                                    placeholder="Enter a message.."
                                    value={newMessage}
                                    size={"2xl"}
                                    px={"5"}
                                    py={"2"}
                                    onChange={typingHandler}
                                />
                                <div className="button cursor-pointer" onClick={toggleDropdown}>
                                    <BsEmojiSmile size={25} className="mt-2" />
                                </div>
                                <Button
                                    variant={"ghost"}
                                    bg={"#ff607b"}
                                    hover={"#ff6061"}
                                    color={"white"}
                                    size={"2xl"}
                                    px={"5"}
                                    py={"2"}
                                    onClick={() => sendMessageBtn()}
                                >
                                    Send
                                </Button>
                            </div>
                        </FormControl>
                        {showEmojis && (
                            <div
                                className="absolute max-md:top-[44rem]  top-[38rem]"
                                ref={dropdownRef}
                            >
                                <EmojiPicker
                                    emojiSize={20}
                                    emojiButtonSize={28}
                                    onEmojiSelect={addEmoji}
                                    previewPosition={"none"}
                                    searchPosition={"none"}
                                    noCountryFlags={false}
                                    navPosition={"top"}
                                    locale={"in"}
                                    categories={["people"]}
                                />
                            </div>
                        )}
                    </Box>
                </>
            ) : (
                <Box d="flex" alignItems="center" justifyContent="center" h="100%">
                    <Text fontSize="3xl" pb={3} fontFamily="Work sans">
                        Click on a user to start chatting
                    </Text>
                </Box>
            )}
        </>
    );
};

export default SingleChat;
