import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuth: false,
  token: "",
  userData: {},
  userProfile: {},
  profileComplete: false,
  oldOtp: "",
  newOtp: "",
  memberData: {},
  memberAddonData: {},
  expirationTime: null,
  SocketConnection: {},
};

export const authSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    isAuth: (state, actions) => {
      state.isAuth = actions.payload.status;
      state.token = actions.payload.userToken;
    },
    userData: (state, actions) => {
      state.expirationTime = actions.payload.expirationTime;
      state.userData = actions.payload.profileData;
    },
    userDataUpdate: (state, actions) => {
      state.userData.email = actions.payload;
    },
    userDataMobileUpdate: (state, actions) => {
      state.userData.mobile = actions.payload;
    },
    userProfile: (state, actions) => {
      state.userProfile = actions.payload;
    },
    isProfile: (state, actions) => {
      state.profileComplete = true;
    },
    setOldOTP: (state, actions) => {
      state.oldOtp = actions.payload.token;
    },
    setNewOTP: (state, actions) => {
      state.newOtp = actions.payload.token;
    },
    removeOldOTP: (state, actions) => {
      state.oldOtp = "";
    },
    removeNewOTP: (state, actions) => {
      state.newOtp = "";
    },
    logoutUser: (state) => {
      state.isAuth = false;
      state.token = "";
      state.userData = {};
      state.userProfile = {};
      state.profileComplete = false;
      state.newOtp = "";
      state.oldOtp = "";
      state.memberData = {};
      state.memberAddonData = {};
      state.expirationTime = null;
      state.SocketConnection = {};
    },
    memberData: (state, actions) => {
      state.memberData = actions.payload;
    },
    memberAddonData: (state, actions) => {
      state.memberAddonData = actions.payload;
    },
    StoreSocket: (state, actions) => {
      state.SocketConnection = actions.payload;
    },
  },
});

export const {
  isAuth,
  userData,
  userDataMobileUpdate,
  logoutUser,
  userProfile,
  isProfile,
  setOldOTP,
  removeOTP,
  setNewOTP,
  removeOldOTP,
  removeNewOTP,
  userDataUpdate,
  memberData,
  memberAddonData,
  StoreSocket,
} = authSlice.actions;

export default authSlice.reducer;
