import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const PrivateDashBoard = () => {
    const userProfileData = useSelector((state) => state.user.userProfile);
    const profileComplete = userProfileData.profileComplet;
    return Math.round(profileComplete) > 71.4 ? <Outlet /> : <Navigate to="/NoAccess" />;
};

export default PrivateDashBoard;
