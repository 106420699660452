import React, { useEffect } from "react";
import { createRoot } from "react-dom/client";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { Provider, useDispatch, useSelector } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { ChatProvider } from "./components/ContextApi/ChatProvider";
import { logoutUser } from "./redux/slice/AuthSlice";
import store from "./redux/store";
import App from "./App";

const SessionTimeout = () => {
    const expirationTime = useSelector((state) => state.user.expirationTime);
    const dispatch = useDispatch();

    useEffect(() => {
        const currentTime = new Date().getTime();
        if (expirationTime && currentTime >= expirationTime) {
            dispatch(logoutUser());
        } else {
            const remainingTimes = expirationTime - currentTime;
            const timeout = setTimeout(() => {
                dispatch(logoutUser());
            }, remainingTimes);

            return () => clearTimeout(timeout);
        }
    }, [dispatch, expirationTime]);

    return null;
};

const theme = extendTheme({
    customTheme: {
        fonts: {
            body: "Helvetica Neue,Helvetica,Arial,sans-serif",
        },
    }
});

const persistor = persistStore(store);
createRoot(document.getElementById("root")).render(
    <ChakraProvider theme={theme}>
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <SessionTimeout />
                <ChatProvider>
                    <App />
                </ChatProvider>
            </PersistGate>
        </Provider>
    </ChakraProvider>
);
