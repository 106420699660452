import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Index from "../index";
import axios from "axios";
import Avaatar from "../../../../assets/AVaaatr.png";
import Female from "../../../../assets/femaleimages.png";
import { Link } from "react-router-dom";
const LookingForMeList = () => {
  const userData = useSelector((state) => state.user.userData);
  const Token = useSelector((state) => state.user.token);
  const [Matches, setMatches] = useState([]);
  const headers = {
    Authorization: `Bearer ${Token}`,
    "Content-Type": "application/x-www-form-urlencoded",
  };

  const GetAllMatches = () => {
    axios
      .get(`${process.env.REACT_APP_FRONTAPICOMMAN}/get-looking-for-me`, {
        headers,
      })
      .then((res) => {
        const slidess = [];
        res.data.LookingForMeUser?.map((res) => {
          const blockedData = res.meBlockByUser?.filter(
            (data) => data.bp_userId === userData._id
          );
          const blockedByData = res.meBlockedUser?.filter(
            (data) => data.bp_block_userId === userData._id
          );
          if (blockedByData?.length !== 0 || blockedData?.length !== 0) {
          } else {
            const ProfileAccess = res?.giveProfileAccessRequestByUsers.filter(
              (res) => res.login_userId === userData._id
            );
            let icon = "";
            if (res.gender === "female") {
              icon = Female;
            } else {
              icon = Avaatar;
            }
            slidess.push({
              id: res._id,
              image: `${
                res.userprofilesList.length !== 0
                  ? res.profileVisible[0]?.profile_image_visible ||
                    ProfileAccess[0] ||
                    res.profileVisible.length === 0
                    ? process.env.REACT_APP_FRONTAPI_IMG +
                      "/uploads/" +
                      res.userprofilesList[0]?.profile_multi_img
                    : icon
                  : icon
              }`,
              title: `${res.fristname}`,
              //   description: `${res.usereducationdetails[0]?.user_edu_high_subcategroy[0]?.ef_title}`,
              clickEvent: "sliderClick",
            });
            setMatches(slidess);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    GetAllMatches();
  }, []);

  return (
    <>
      <Index Name="filter" />
      <div
        className="container-fluid pt-5 overflow-y-scroll"
        style={{ minHeight: "500px" }}
      >
        <div className="container mb-5">
          <div className="row mb-5">
            <h2 className="p-4 text-black  bolder text-5xl my-5">
              My Matches Profiles
            </h2>
            <div className="flex gap-6 flex-wrap ">
              {Matches.map((imageUrl, index) => {
                return (
                  <Link
                    key={index}
                    className="px-5 py-2"
                    style={{ border: "3px dashed pink" }}
                    to={`/dashboard/profile/${imageUrl.id}`}
                  >
                    <div className="slider">
                      <img
                        src={imageUrl.image}
                        className="w-72 h-72 object-cover"
                        alt="movie"
                      />
                      <h3 className="pt-8 font-semibold text-3xl caption">
                        {imageUrl.title}
                      </h3>
                      <p className="pb-4 text-lg caption">
                        {imageUrl.description}
                      </p>
                    </div>
                  </Link>
                );
              })}
              {Matches.length === 0 && (
                <div className="row my-5 pt-5 pb-10 duration-300 border border-rose-400 flex justify-center text-5xl text-rose-500">
                  No More Profiles
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LookingForMeList;
