import React, { Suspense, lazy, useContext, useEffect } from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./App.css";
import PrivateDashBoard from "./utility/PrivateDashBoard";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ChatContext } from "./components/ContextApi/ChatProvider";
import axios from "axios";
import { userProfile } from "./redux/slice/AuthSlice";
import ChatPage from "./components/Pages/dashboard/filter/Pages/ChatPage";
import Error from "./Error";
import LookingForMeList from "./components/Pages/dashboard/LookingForMeList/LookingForMeList";
import errorHandler from "./utility/errorHandler";

const NavBar = lazy(() => import("./components/navbar/NavBar"));
const About = lazy(() => import("./components/Pages/about/About"));
const Contact = lazy(() => import("./components/Pages/contact/Contact"));
const Stories = lazy(() => import("./components/Pages/stories/Stories"));
const Login = lazy(() => import("./components/Pages/login/Login"));
const Register = lazy(() => import("./components/Pages/register/Register"));
const RegisterStep = lazy(() => import("./components/Pages/register/RegisterStep"));
const Home = lazy(() => import("./components/home/Home"));
const Footer = lazy(() => import("./components/footer/Footer"));
const Terms = lazy(() => import("./components/Pages/terms/Terms"));
const Membership = lazy(() => import("./components/Pages/membership/Membership"));
const Help = lazy(() => import("./components/Pages/help/Help"));
const Faq = lazy(() => import("./components/Pages/faq/Faq"));
const OurStories = lazy(() => import("./components/Pages/stories/OurStories"));
const Matches = lazy(() => import("./components/Pages/dashboard/myMatches/Matches"));
const SuggestedMatches = lazy(() => import("./components/Pages/dashboard/SuggestedMatch/SuggestedMatch"));
const PremiumMatches = lazy(() => import("./components/Pages/dashboard/PremiumMatches/PremiumMatches"));
const Blog = lazy(() => import("./components/Pages/Blog/blog"));
const Privacy = lazy(() => import("./components/Pages/privacy/Privacy"));
const PlanHistory = lazy(() => import("./components/Pages/dashboard/PlanHistory/PlanHistory"));
const Filter = lazy(() => import("./components/Pages/dashboard/filter/Filter"));
const Profile = lazy(() => import("./components/Pages/dashboard/profile/Profile"));
const Dashboard = lazy(() => import("./components/Pages/dashboard/Dashboard"));
const Prefrences = lazy(() => import("./components/Pages/dashboard/partnerPrefrences/Prefrences"));
const Photos = lazy(() => import("./components/Pages/dashboard/myPhotos/Photos"));
const MyProfile = lazy(() => import("./components/Pages/dashboard/myProfile/MyProfile"));
const PersonalEdit = lazy(() => import("./components/Pages/dashboard/EditForm/PersonalEdit"));
const AddressEdit = lazy(() => import("./components/Pages/dashboard/EditForm/AddressEdit"));
const KycEdit = lazy(() => import("./components/Pages/dashboard/EditForm/KycEdit"));
const EducationEdit = lazy(() => import("./components/Pages/dashboard/EditForm/EducationEdit"));
const FamilyEdit = lazy(() => import("./components/Pages/dashboard/EditForm/FamilyEdit"));
const PartnerEdit = lazy(() => import("./components/Pages/dashboard/EditForm/PartnerEdit"));
const Horoscope = lazy(() => import("./components/Pages/dashboard/EditForm/Horoscope"));
const ProfileEdit = lazy(() => import("./components/Pages/dashboard/EditForm/ProfileEdit"));
const SingleBlog = lazy(() => import("./components/Pages/Blog/SingleBlog"));
const AddPlan = lazy(() => import("./components/Pages/addPlan/AddPlan"));
const PrivateRoutes = lazy(() => import("./utility/PrivateRoutes"));
const ForgetPassword = lazy(() => import("./components/Pages/forgetPassword/ForgetPassword"));
const ResetPassword = lazy(() => import("./components/Pages/forgetPassword/ResetPassword"));
const PrivacySetting = lazy(() => import("./components/Pages/dashboard/accountSetting/Privacy/Privacy"));
const AccountPassword = lazy(() => import("./components/Pages/dashboard/accountSetting/changePassword/ChangePassword"));
const UpdateProfile = lazy(() => import("./components/Pages/dashboard/accountSetting/updateProfile/UpdateProfile"));
const UpdateEmail = lazy(() => import("./components/Pages/dashboard/accountSetting/updateProfile/updateEmail/UpdateEmail"));
const EmailUpdate = lazy(() => import("./components/Pages/dashboard/accountSetting/updateProfile/updateEmail/EmailUpdate"));
const OldNumberOtpVerify = lazy(() => import("./components/Pages/dashboard/accountSetting/updateProfile/updateNumber/OldNumberOtpVerify"));
const NewNumberOtpVerify = lazy(() => import("./components/Pages/dashboard/accountSetting/updateProfile/updateNumber/NewNumberOTPVerify"));
const CheckoutAddon = lazy(() => import("./components/Pages/checkout/CheckoutAddon"));
const ShortListed = lazy(() => import("./components/Pages/dashboard/ShortlistedProfile/Shortlisted"));
const PhotoRequest = lazy(() => import("./components/Pages/dashboard/RequestPhoto/RequestPhoto"));
const ExpressInterst = lazy(() => import("./components/Pages/dashboard/ExpressInterst/ExpressInterst"));
const ExpressInterstSend = lazy(() => import("./components/Pages/dashboard/ExpressInterst/SendByMe"));
const ExpressInterstReject = lazy(() => import("./components/Pages/dashboard/ExpressInterst/RejectByUser"));
const ExpressInterstRejectByMe = lazy(() => import("./components/Pages/dashboard/ExpressInterst/RejectByMe"));
const ExpressInterstRecived = lazy(() => import("./components/Pages/dashboard/ExpressInterst/RecievedByMe"));
const ExpressInterstAccepted = lazy(() => import("./components/Pages/dashboard/ExpressInterst/AcceptByMe"));
const ExpressInterstAcceptByUser = lazy(() => import("./components/Pages/dashboard/ExpressInterst/AcceptByUser"));
const PhotoRequestSend = lazy(() => import("./components/Pages/dashboard/RequestPhoto/RequestSend"));
const PhotoRequestRecieved = lazy(() => import("./components/Pages/dashboard/RequestPhoto/RequestRecieved"));
const BlockList = lazy(() => import("./components/Pages/dashboard/BlockProfile/BlockProfile"));
const UserProfileSingle = lazy(() => import("./components/Pages/dashboard/SingleProfile/SingleProfile"));
const Checkout = lazy(() => import("./components/Pages/checkout/Checkout"));
const AddMorePhoto = lazy(() => import("./components/Pages/dashboard/myPhotos/AddMorePhoto"));
const AbuseList = lazy(() => import("./components/Pages/dashboard/AbuseProfile/AbuseProfile"));
const Visitor = lazy(() => import("./components/Pages/dashboard/Visitor/Visitor"));
const AddToCart = lazy(() => import("./components/Pages/addToCart/AddToCart"));
const EmailVerify = lazy(() => import("./components/Pages/dashboard/accountSetting/EmailVerify/EmailVerify"));
const SingleAllPhotos = lazy(() => import("./components/Pages/dashboard/filter/SingleAllPhotos"));

// const Messenger = lazy(() => import("./components/Pages/dashboard/filter/Chat/Messenger/Messenger"));
// const Chat = lazy(() => import("./components/Pages/dashboard/filter/Chat/Chat"));

const NoAccess = lazy(() => import("./components/NoAccess/NoAccess"));
const App = () => {
    const { Step } = useContext(ChatContext);
    const [currentStep, setCurrentStep] = Step;
    const userProfileData = useSelector((state) => state.user.userProfile);
    const PersonalDetails = userProfileData?.PersonalDetails;
    const UserEducationDetail = userProfileData?.UserEducationDetail;
    const FamilyDetails = userProfileData?.FamilyDetails;
    const UserAddresDetail = userProfileData?.UserAddresDetail;
    const UserProfile = userProfileData?.UserProfile;
    const UserKyc = userProfileData?.UserKyc;
    const PartnerPreference = userProfileData?.PartnerPreference;

    const Dispatch = useDispatch();
    const userData = useSelector((state) => state.user.userData);
    const userId = userData._id;
    useEffect(() => {
        switch (true) {
            case PersonalDetails === 0:
                setCurrentStep(1);
                break;
            case PersonalDetails === 1 && UserEducationDetail === 0:
                setCurrentStep(2);
                break;
            case PersonalDetails === 1 &&
                UserEducationDetail === 1 &&
                FamilyDetails === 0:
                setCurrentStep(3);
                break;
            case PersonalDetails === 1 &&
                UserEducationDetail === 1 &&
                FamilyDetails === 1 &&
                UserAddresDetail === 0:
                setCurrentStep(4);
                break;
            case PersonalDetails === 1 &&
                UserEducationDetail === 1 &&
                FamilyDetails === 1 &&
                UserAddresDetail === 1 &&
                UserProfile === 0:
                setCurrentStep(5);
                break;
            case PersonalDetails === 1 &&
                UserEducationDetail === 1 &&
                FamilyDetails === 1 &&
                UserAddresDetail === 1 &&
                UserProfile >= 1 &&
                UserKyc === 0:
                setCurrentStep(6);
                break;
            case PersonalDetails === 1 &&
                UserEducationDetail === 1 &&
                FamilyDetails === 1 &&
                UserAddresDetail === 1 &&
                UserProfile >= 1 &&
                UserKyc === 1 &&
                PartnerPreference === 0:
                setCurrentStep(7);
                break;
            default:
                // Navigate("/dashboard");
                // Dispatch(isProfile());
                break;
        }

        if (userId) {
            axios.get(`${process.env.REACT_APP_FRONTAPICOMMAN}/get-profile-complete-status?userId=${userId}`)
                .then((res) => { Dispatch(userProfile(res.data)); })
                .catch(errorHandler);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        // const handleContextMenu = (event) => {
        //     event.preventDefault();
        // };

        // const handleKeyDown = (event) => {
        //     if (event.keyCode === 123) {
        //         event.preventDefault();
        //     } else if (
        //         (event.ctrlKey && event.shiftKey && event.keyCode === 73) ||
        //         (event.ctrlKey && event.shiftKey && event.keyCode === 74)
        //     ) {
        //         event.preventDefault();
        //     }
        // };

        // window.addEventListener("contextmenu", handleContextMenu);
        // document.addEventListener("keydown", handleKeyDown);

        // return () => {
        //     window.removeEventListener("contextmenu", handleContextMenu);
        //     document.removeEventListener("keydown", handleKeyDown);
        // };
    }, []);

    return (
        <>
            <ToastContainer position="top-right" className="text-red-500" autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light" />
            <Router>
                <Suspense fallback={<h1 className="grid mt-64 text-4xl text-red-500 place-items-center">Loading...</h1>}>
                    <NavBar />
                    <Routes>
                        <Route path="*" element={<Error />} />
                        <Route path="/" element={<Home />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/contact" element={<Contact />} />
                        <Route path="/stories" element={<Stories />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/forgetPassword" element={<ForgetPassword />} />
                        <Route path="/resetPassword" element={<ResetPassword />} />
                        <Route path="/register" element={<Register />} />
                        <Route path="/terms" element={<Terms />} />
                        <Route path="/membership" element={<Membership />} />
                        <Route path="/addToCart" element={<AddToCart />} />
                        <Route path="/help" element={<Help />} />
                        <Route path="/privacy" element={<Privacy />} />
                        <Route path="/faq" element={<Faq />} />
                        <Route path="/blog" element={<Blog />} />
                        <Route path="/ourStories" element={<OurStories />} />
                        <Route element={<PrivateRoutes />}>
                            <Route path="/NoAccess" element={<NoAccess />} />
                            <Route path="/registerstep" element={<RegisterStep />} />
                            <Route element={<PrivateDashBoard />}>
                                <Route path="/dashboard/planhistory" element={<PlanHistory />} />
                                <Route path="/dashboard/mymatches" element={<Matches />} />
                                <Route path="/dashboard/mysuggestedmatches" element={<SuggestedMatches />} />
                                <Route path="/dashboard/lookingforme" element={<LookingForMeList />} />
                                <Route path="/dashboard/PremiumMatches" element={<PremiumMatches />} />
                                <Route path="/dashboard/user/Shortlist_Profile" element={<ShortListed />} />
                                <Route path="/dashboard/user/photos/:id" element={<SingleAllPhotos />} />
                                <Route path="/dashboard/user/Block_Profile" element={<BlockList />} />
                                <Route path="/dashboard/user/Photo_request" element={<PhotoRequest />} />
                                <Route path="/dashboard/user/Photo_request/send" element={<PhotoRequestSend />} />
                                <Route path="/dashboard/user/Photo_request/recieved" element={<PhotoRequestRecieved />} />
                                <Route path="/dashboard/user/Express_interst" element={<ExpressInterst />} />
                                <Route path="/dashboard/user/Express_interst/send" element={<ExpressInterstSend />} />
                                <Route path="/dashboard/user/Express_interst/RejectByUser" element={<ExpressInterstReject />} />
                                <Route path="/dashboard/user/Express_interst/RejectByMe" element={<ExpressInterstRejectByMe />} />
                                <Route path="/dashboard/user/Express_interst/AcceptByUser" element={<ExpressInterstAcceptByUser />} />
                                <Route path="/dashboard/user/Express_interst/recieved" element={<ExpressInterstRecived />} />
                                <Route path="/dashboard/user/Express_interst/accepted" element={<ExpressInterstAccepted />} />
                                <Route path="/dashboard/user/Abuse_Profile" element={<AbuseList />} />
                                <Route path="/dashboard/profile/:profile_id" element={<UserProfileSingle />} />
                                <Route path="/dashboard/account/changepassword" element={<AccountPassword />} />
                                <Route path="/dashboard/account/privacy" element={<PrivacySetting />} />
                                <Route path="/dashboard/account/update" element={<UpdateProfile />} />
                                <Route path="/dashboard/account/update/email" element={<UpdateEmail />} />
                                <Route path="/dashboard/account/update/email/update" element={<EmailUpdate />} />
                                <Route path="/dashboard/account/update/oldnumber" element={<OldNumberOtpVerify />} />
                                <Route path="/dashboard/account/update/newnumber" element={<NewNumberOtpVerify />} />
                                <Route path="/dashboard/profile" element={<Profile />} />
                                <Route path="/dashboard" element={<Dashboard />} />
                                <Route path="/dashboard/prefrences" element={<Prefrences />} />
                                <Route path="/dashboard/myPhotos" element={<Photos />} />
                                <Route path="/dashboard/myProfile" element={<MyProfile />} />
                                <Route path="/dashboard/membership/checkout" element={<Checkout />} />
                                <Route path="/chats" element={<ChatPage />} />
                                {/* <Route path="/dashboard/messenger" element={<Messenger />} /> */}
                                {/* <Route path="/dashboard/chat" element={<Chat />} /> */}
                                <Route path="/dashboard/membership/checkoutAddon" element={<CheckoutAddon />} />
                                <Route path="/dashboard/Personaledit/" element={<PersonalEdit />} />
                                <Route path="/dashboard/Addressedit" element={<AddressEdit />} />
                                <Route path="/dashboard/Kycedit" element={<KycEdit />} />
                                <Route path="/dashboard/Educationedit" element={<EducationEdit />} />
                                <Route path="/dashboard/Horoscope/:id?" element={<Horoscope />} />
                                <Route path="/dashboard/emailverify" element={<EmailVerify />} />
                                <Route path="/dashboard/profile/image/edit/:id" element={<ProfileEdit />} />
                                <Route path="/dashboard/Familyedit/" element={<FamilyEdit />} />
                                <Route path="/dashboard/Partneredit" element={<PartnerEdit />} />
                                <Route path="/dashboard/filter" element={<Filter />} />
                                <Route path="/dashboard/visitor" element={<Visitor />} />
                                <Route path="/dashboard/Add_More_Photos/:pic" element={<AddMorePhoto />} />
                            </Route>
                        </Route>
                        <Route path="/addonplan" element={<AddPlan />} />
                        <Route path="/singleblog/:id" element={<SingleBlog />} />
                    </Routes>
                    <Footer />
                </Suspense>
            </Router>
        </>
    );
};

export default React.memo(App);
