import React, { useContext } from "react";
import { ChatContext } from "../../../../ContextApi/ChatProvider";
import { Box } from "@chakra-ui/react";
import SingleChat from "./SingleChat";

const ChatBox = ({ fetchAgain, setFetchAgain }) => {
    const { selectedChat } = useContext(ChatContext);

    return (
        <Box
            display={{ base: selectedChat ? "flex" : "none", md: "flex" }}
            alignItems="center"
            flexDir="column"
            p={3}
            bg="white"
            w={{ base: "100%", md: "68%" }}
            borderRadius="lg"
            borderWidth="1px"
        >
            <SingleChat
                block={false}
                fetchAgain={fetchAgain}
                setFetchAgain={setFetchAgain}
            />
        </Box>
    );
};

export default ChatBox;
