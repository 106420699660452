import React, { createContext, useEffect, useState } from "react";
export const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
    const [user, setUser] = useState();
    const [selectedChat, setSelectedChat] = useState();
    const [chats, setChats] = useState([]);
    const [notification, setNotification] = useState([]);
    const [currentStep, setCurrentStep] = useState(1);
    const [chatCount, setChatCount] = useState();
    const [block, setBlock] = useState([]);
    const [newMessage, setNewMessage] = useState("");
    const [typing, setTyping] = useState(false);
    const [messages, setMessages] = useState([]);
    const [istyping, setIsTyping] = useState(false);

    useEffect(() => {
        const userinfo = JSON.parse(localStorage.getItem("userInfo"));
        setUser(userinfo);
    }, []);

    return (
        <ChatContext.Provider
            value={{
                user,
                setUser,
                selectedChat,
                setSelectedChat,
                chats,
                setChats,
                notification,
                setNotification,
                chatCount,
                setChatCount,
                block,
                setBlock,
                newMessage,
                setNewMessage,
                typing,
                setTyping,
                messages,
                setMessages,
                istyping,
                setIsTyping,
                Step: [currentStep, setCurrentStep],
            }}
        >
            {children}
        </ChatContext.Provider>
    );
};
