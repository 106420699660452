import React, { useEffect, useRef, useState } from "react";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";
import { NavLink } from "react-router-dom";

const Index = ({ Name }) => {
  const [isOpen, setIsOpen] = useState(false);

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleMouseEnters = () => {
    setIsOpen(true);
  };

  const handleMouseLeaves = () => {
    setIsOpen(false);
  };

  // const toggleDropdown = () => {
  //   setIsOpen(!isOpen);
  // };
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const toggleDropdown = () => {
    setIsOpen((prevState) => !prevState);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div className="dashboard flex justify-center  bg-gray-200 shadow-md py-6">
      <ul className="grid grid-cols-1 md:grid-cols-7 place-items-center">
        <li className="text-2xl font-semibold mr-7">
          <NavLink
            to="/dashboard"
            className={`${Name === "dashboard" && "text-red-500"}`}
          >
            Dashboard
          </NavLink>
        </li>
        <li className="text-2xl font-semibold mr-7">
          <NavLink
            to="/dashboard/myProfile"
            className={`${Name === "myprofiledata" && "text-red-500"}`}
          >
            My Profile
          </NavLink>
        </li>
        <li className="text-2xl font-semibold mr-7">
          <NavLink
            to="/dashboard/myPhotos"
            className={`${Name === "myphotos" && "text-red-500"}`}
          >
            My Photos
          </NavLink>
        </li>
        <div
          className="text-2xl font-semibold mr-7 relative"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <button className="text-black px-3 py-2 rounded-md flex justify-center items-center">
            My Matches{" "}
            <RiArrowUpSLine
              className={`${isHovered ? "" : "rotate-180"} duration-200`}
            />
          </button>
          {isHovered && (
            <ul
              onMouseEnter={handleMouseEnter}
              className="absolute bg-gray-200 rounded-md py-2 w-56 text-xl z-50"
            >
              <li className="hover:bg-gray-200 px-3 py-1">
                <NavLink
                  to="/dashboard/lookingforme"
                  className={`${Name === "short" && "text-red-500"}`}
                >
                  Looking For Me
                </NavLink>
              </li>
              <li className="hover:bg-gray-200 px-3 py-1">
                <NavLink
                  to="/dashboard/mymatches"
                  className={`${Name === "short" && "text-red-500"}`}
                >
                  My Matches
                </NavLink>
              </li>
              <li className="hover:bg-gray-200 px-3 py-1">
                <NavLink
                  to="/dashboard/mysuggestedmatches"
                  className={`${Name === "short" && "text-red-500"}`}
                >
                  Suggested Matches
                </NavLink>
              </li>
            </ul>
          )}
        </div>
        <li className="text-2xl font-semibold mr-7">
          <NavLink
            to="/dashboard/prefrences"
            className={`${Name === "partner" && "text-red-500"}`}
          >
            Partner Preferences
          </NavLink>
        </li>

        <li className="text-2xl font-semibold mr-7">
          <NavLink
            to="/dashboard/filter"
            className={`${Name === "filter" && "text-red-500"}`}
          >
            Filter
          </NavLink>
        </li>
        <div
          className="text-2xl font-semibold mr-7 relative"
          onMouseEnter={handleMouseEnters}
          onMouseLeave={handleMouseLeaves}
        >
          <button className="text-black px-3 py-2 rounded-md flex justify-center items-center">
            More{" "}
            <RiArrowUpSLine
              className={`${isOpen ? "" : "rotate-180"} duration-200`}
            />
          </button>
          {isOpen && (
            <ul
              onMouseEnter={handleMouseEnters}
              className="absolute bg-gray-200 rounded-md py-2 w-56 text-xl z-50"
              ref={dropdownRef}
            >
              <li className="hover:bg-gray-200 px-3 py-1">
                <NavLink
                  to="/dashboard/account/update"
                  className={`${Name === "profile" && "text-red-500"}`}
                >
                  Update Contact
                </NavLink>
              </li>
              <li className="hover:bg-gray-200 px-3 py-1">
                <NavLink
                  to="/dashboard/user/Shortlist_Profile"
                  className={`${Name === "short" && "text-red-500"}`}
                >
                  Short List
                </NavLink>
              </li>
              <li className="hover:bg-gray-200 px-3 py-1">
                <NavLink
                  to="/dashboard/user/Block_Profile"
                  className={`${Name === "block" && "text-red-500"}`}
                >
                  Block List
                </NavLink>
              </li>
              <li className="hover:bg-gray-200 px-3 py-1">
                <NavLink
                  to="/dashboard/user/Abuse_Profile"
                  className={`${Name === "abuse" && "text-red-500"}`}
                >
                  Abuse List
                </NavLink>
              </li>
              <li className="hover:bg-gray-200 px-3 py-1">
                <NavLink
                  to="/dashboard/user/Express_interst"
                  className={`${Name === "abuse" && "text-red-500"}`}
                >
                  Express Interst
                </NavLink>
              </li>
              <li className="hover:bg-gray-200 px-3 py-1">
                <NavLink
                  to="/dashboard/user/Photo_request"
                  className={`${Name === "abuse" && "text-red-500"}`}
                >
                  Photo Request
                </NavLink>
              </li>
              <li className="hover:bg-gray-200 px-3 py-1">
                <NavLink
                  to="/dashboard/planhistory"
                  className={`${Name === "abuse" && "text-red-500"}`}
                >
                  Plan History
                </NavLink>
              </li>
            </ul>
          )}
        </div>
      </ul>
    </div>
  );
};

export default Index;
